import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import {
  XIcon,
  ClipboardCheckIcon,
  CurrencyEuroIcon,
  InboxInIcon,
  UsersIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import Navigation from "./navigation";

export default function ResponsiveNavigation({
  profile,
  sidebarOpen,
  setSidebarOpen,
}) {
  const { t } = useTranslation();
  const mainItems = [
    {
      name: t("navigation.main.clients"),
      icon: UsersIcon,
      current: true,
      href: "/clients",
      role: ["Admin"],
    },
    {
      name: t("navigation.main.articles"),
      icon: ShoppingCartIcon,
      current: false,
      href: "/articles",
      role: ["Admin", "Client"],
    },
    {
      name: t("navigation.main.orders"),
      icon: InboxInIcon,
      current: false,
      href: "/orders",
      role: ["Admin", "Client"],
    },
    {
      name: t("navigation.main.deliveryNotes"),
      icon: ClipboardCheckIcon,
      current: false,
      href: "/deliveryNotes",
      role: ["Admin"],
    },
    {
      name: t("navigation.main.bills"),
      icon: CurrencyEuroIcon,
      current: false,
      href: "/invoices",
      role: ["Admin", "Client"],
    },
  ];
  const secondaryItems = [
    {
      name: t("navigation.sub.accounts"),
      href: "/accounts",
      role: ["Admin"],
    },
    {
      name: t("navigation.sub.apiInterface"),
      href: `${process.env.GATSBY_STRAPI_URL}/admin`,
      target: "_blank",
      role: ["Admin"],
    },
    {
      name: t("navigation.sub.apiStatus"),
      href: `${process.env.GATSBY_STATUS_PAGE}`,
      target: "_blank",
      role: ["Admin", "Client"],
    },
  ];
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          onClose={setSidebarOpen}
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-400 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-100">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <Navigation
                profile={profile}
                mainItems={mainItems}
                secondaryItems={secondaryItems}
              />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true" />
        </Dialog>
      </Transition.Root>
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="lg:flex lg:flex-col md:w-48 lg:w-64 md:hidden">
          <div className="lg:flex lg:flex-col flex-grow pb-4 bg-white overflow-y-auto md:hidden shadow">
            <Navigation
              profile={profile}
              mainItems={mainItems}
              secondaryItems={secondaryItems}
            />
          </div>
        </div>
      </div>
    </>
  );
}
