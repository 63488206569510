import { toast } from "react-toastify";

export const saveCart = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};
export const getCart = () => {
  try {
    const cart = JSON.parse(localStorage.getItem("cart"));
    if (cart) {
      return cart;
    }
  } catch (err) {
    toast.error("Cart error");
  }

  return {};
};
