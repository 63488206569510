import React from "react";
import NoImageSquare from "../../images/favicon.png";

export default function CProfileImage(props) {
  const { imageURL, className } = props;
  const imageDomain = process.env.GATSBY_STRAPI_URL;
  return (
    <img
      className={className}
      src={imageURL && imageURL !== "" ? imageDomain + imageURL : NoImageSquare}
      alt="Profile Icon"
    />
  );
}
