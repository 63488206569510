import { gql } from "@apollo/client/core";

export const ME_QUERY = gql`
  query {
    me {
      id
      username
      email
      profile {
        data {
          id
          attributes {
            url
          }
        }
      }
      role {
        name
      }
      client {
        data {
          id
          attributes {
            businessName
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export default {};
