import React from "react";
import { Link, navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import MyPotatoLogo from "../images/favicon.png";
import { logout } from "../services/auth/auth";
import CProfileImage from "./utils/CProfileImage";
import { useUserInfo } from "./providers/user";

export default function Navigation({ profile, mainItems, secondaryItems }) {
  const { t } = useTranslation();

  const [user] = useUserInfo();

  const execLogout = () => {
    logout();
    navigate("/");
    user?.cache?.reset();
  };
  return (
    <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
      <div className="flex-1 flex flex-col pt-1 pb-4">
        <Link to="/">
          <div className="flex-shrink-0 flex items-center px-2 py-2">
            <img
              className="h-14 ml-3 rounded-full"
              src={MyPotatoLogo}
              alt="My Potato Logo"
            />
            <p className="font-bold tracking-tight text-2xl text-gray-900">
              {t("logo.title")}
            </p>
          </div>
        </Link>
        {/* <div className="mt-5 flex-1 h-0 overflow-y-auto"> */}
        <nav className="px-2 space-y-1">
          {mainItems?.map((item) =>
            !item.children && item?.role?.includes(user.role?.name) ? (
              <div key={item.name}>
                <Link
                  to={item.href}
                  className={
                    (item.current
                      ? "bg-gray-300 text-gray-900"
                      : "text-gray-600 hover:bg-gray-200 hover:text-gray-900",
                    "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-50")
                  }
                >
                  <item.icon
                    className={
                      (item.current
                        ? "text-gray-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "mr-3 flex-shrink-0 h-6 w-6")
                    }
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              </div>
            ) : null,
          )}
        </nav>
      </div>
      <div className="flex-shrink-0 flex flex-col">
        <div className="border-t border-b border-gray-300">
          <div className="flex-shrink-0 w-full group block p-4">
            <div className="flex items-center">
              <CProfileImage
                imageURL={profile?.profile?.data?.attributes?.url}
                className="inline-block h-10 w-10 rounded-full bg-white object-cover object-center"
              />
              <div className="ml-3">
                <p className="text-sm font-medium ">{profile.username}</p>
                <p>
                  <Link to="/me">
                    <span className="text-xs font-medium hover:text-gray-400">
                      {t("navigation.sub.myProfile")}
                    </span>
                  </Link>
                  <span>{" • "}</span>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <span
                    onClick={execLogout}
                    className="text-xs font-medium hover:text-gray-400 cursor-pointer"
                  >
                    {t("account.signOutBtn")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-1 p-4">
          <div
            className="space-y-1"
            role="group"
            aria-labelledby="projects-headline"
          >
            {secondaryItems.map((item) => {
              if (item?.target && item?.role?.includes(user?.role?.name)) {
                return (
                  <a
                    target={item?.target}
                    key={item?.name}
                    href={item?.href}
                    className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                  >
                    {item?.name}
                  </a>
                );
              }
              if (item?.role?.includes(user?.role?.name)) {
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                  >
                    <span className="truncate">{item.name}</span>
                  </Link>
                );
              }
              return null;
            })}
          </div>
        </div>
        <span className=" pl-4 pr-2 pt-4 text-sm font-medium text-gray-400 border-t border-gray-300">
          © {new Date().getFullYear()} • My Potato GmbH
        </span>
      </div>
    </div>
  );
}
