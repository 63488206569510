import React, { createContext, useState } from "react";

import { getCart, saveCart } from "../utils/Cart";

export const CartContext = createContext([null, () => {}, () => {}]);

export function CartContextProvider({ children }) {
  const [cart, setCart] = useState(getCart());

  const updateCart = (updatedCart) => {
    setCart(updatedCart);
    saveCart(updatedCart);
  };

  const addToCart = (id, quantity = 1) => {
    const copy = { ...cart };
    // Update the quantity
    copy[id] = Number(quantity);

    if (copy[id] === 0) {
      // Remove the product from the cart
      delete copy[id];
    }

    updateCart(copy);
  };

  const clearCart = () => {
    const updatedCart = [];
    updateCart(updatedCart);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CartContext.Provider value={[cart, addToCart, clearCart]}>
      {children}
    </CartContext.Provider>
  );
}
export const CartContextConsumer = CartContext.Consumer;
