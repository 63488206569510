import React from "react";
import { useTranslation } from "react-i18next";
import { BellIcon, MenuAlt2Icon } from "@heroicons/react/outline";

function Header({ setSidebarOpen }) {
  const { t } = useTranslation();
  return (
    <div className="relative lg:z-10 flex-shrink-0 flex h-16 bg-white shadow lg:hidden">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">{t("header.OpenSidebar.text")}</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 flex justify-between hidden">
        <div className="ml-4 flex items-center md:ml-6">
          <button
            type="button"
            className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="sr-only">{t("header.view.notifications")}</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
export default Header;
