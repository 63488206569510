import React, { useState } from "react";
import Header from "./header";
import withTrans from "../i18n/withTrans";
import ResponsiveNavigation from "./responsive-navigation";
import { getUser } from "../services/auth/auth";
import NotAuthorized from "../pages/notAuthorized";
import { useUserInfo } from "./providers/user";
import NavigationHeader from "./navigation-header";
import Footer from "./footer";

function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [user] = useUserInfo();

  /*
  user &&
    getUser().id !== undefined &&
    ["Client"].includes(user.role?.name) &&
    (children.props?.location?.pathname === "/accounts/" ||
      children.props?.location?.pathname === "/accounts" ||
      children.props?.location?.pathname === "/deliveryNotes" ||
      children.props?.location?.pathname === "/clients" ||
      children.props?.location?.pathname === "/deliveryNotes/" ||
      children.props?.location?.pathname === "/clients/") ? (
    <NotAuthorized />
  ) : (
    <div>
      <div className="h-screen flex md:bg-gray-50">
        {user &&
          getUser().id !== undefined &&
          ["Client", "Admin"].includes(user?.role?.name) && (
            <ResponsiveNavigation
              profile={user}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          )}
        <div className="flex flex-col w-0 flex-1">
          {user &&
            getUser().id !== undefined &&
            ["Client", "Admin"].includes(user?.role?.name) && (
              <Header
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
            )}
          <div
            className={getUser().id ? "overflow-y-auto" : "overflow-visible"}
          >
            {!getUser().id && (
              <NavigationHeader open={open} setOpen={setOpen} />
            )}
            <main className="flex-1 relative focus:outline-none">
              {children}
            </main>
            {!getUser().id && <Footer />}
          </div>
        </div>
      </div>
    </div>

    return user &&
    getUser().id !== undefined &&
    ["Client"].includes(user.role?.name) &&
    (children.props?.location?.pathname === "/accounts/" ||
      children.props?.location?.pathname === "/accounts" ||
      children.props?.location?.pathname === "/deliveryNotes" ||
      children.props?.location?.pathname === "/clients" ||
      children.props?.location?.pathname === "/deliveryNotes/" ||
      children.props?.location?.pathname === "/clients/") ? (
    <NotAuthorized />
  ) : (
    <div
      className={
        getUser().id !== undefined
          ? "overflow-hidden h-screen flex md:bg-gray-50"
          : "h-screen flex md:bg-gray-50"
      }
    >
      {user &&
        getUser().id !== undefined &&
        ["Client", "Admin"].includes(user?.role?.name) && (
          <ResponsiveNavigation
            profile={user}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        )}
      <div
        className={
          getUser().id !== undefined
            ? "flex flex-col w-0 flex-1 overflow-hidden"
            : "flex flex-col w-0 flex-1"
        }
      >
        {user &&
          getUser().id !== undefined &&
          ["Client", "Admin"].includes(user?.role?.name) && (
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          )}
        <div className={getUser().id ? "overflow-y-auto" : "overflow-visible"}>
          {!getUser().id && <NavigationHeader open={open} setOpen={setOpen} />}
          <main className="flex-1 relative focus:outline-none">{children}</main>
          {!getUser().id && <Footer />}
        </div>
      </div>
    </div>
   */

  return user &&
    getUser().id !== undefined &&
    ["Client"].includes(user.role?.name) &&
    (children.props?.location?.pathname === "/accounts/" ||
      children.props?.location?.pathname === "/accounts" ||
      children.props?.location?.pathname === "/deliveryNotes" ||
      children.props?.location?.pathname === "/clients" ||
      children.props?.location?.pathname === "/deliveryNotes/" ||
      children.props?.location?.pathname === "/clients/") ? (
    <NotAuthorized />
  ) : (
    <div>
      {!getUser().id && <NavigationHeader open={open} setOpen={setOpen} />}
      <div className="h-screen flex overflow-hidden md:bg-gray-50">
        {user &&
          getUser().id !== undefined &&
          ["Client", "Admin"].includes(user?.role?.name) && (
            <ResponsiveNavigation
              profile={user}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          )}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {user &&
            getUser().id !== undefined &&
            ["Client", "Admin"].includes(user?.role?.name) && (
              <Header
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
            )}
          <main className="flex-1 relative overflow-y-auto focus:outline-none">
            {children}
          </main>
        </div>
      </div>
      {!getUser().id && <Footer />}
    </div>
  );
}
export default withTrans(Layout);
